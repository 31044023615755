import { Controller } from "@hotwired/stimulus";
import createChannel from '../cable';

// Connects to data-controller="single-cohort-report"
export default class extends Controller {
  static targets = ['report', 'reportProgressBar', 'reportProgress'];

  connect() {
    let thisController = this;
    const totalRosterings = this.data.get('total-rosterings');
    let processedRosterings = 0;

    createChannel({ channel: 'CohortReportChannel', request_id: this.data.get('request') }, {
      received({ report }) {
        console.log("received data");
        thisController.reportTarget.innerHTML = report;
      },
    });

    createChannel({ channel: 'ReportProgressNotifierChannel', request_id: this.data.get('request') }, {
      received({ rostering_id }) {
        console.log("Received data in ReportProgressNotifierChannel");
        processedRosterings += 1;
        const percentage = Math.round((processedRosterings / totalRosterings) * 100);

        if (thisController.hasReportProgressBarTarget) {
          thisController.reportProgressBarTarget.style.width = `${percentage}%`;
          thisController.reportProgressBarTarget.setAttribute('aria-valuenow', percentage);
          thisController.reportProgressBarTarget.innerHTML = `${percentage}%`;
        } else {
          console.error("Report progress bar target is not defined.");
        }   
      }
    });
  }
}
